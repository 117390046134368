import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Locale = "en" | "lt";

export const Landing = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const goToIntro = (locale: Locale) => {
    i18n.changeLanguage(locale);
    navigate("/intro");
  };

  return (
    <div className="flex h-screen justify-center overflow-hidden bg-opacity-50 bg-[url('./homepage.jpg')] bg-cover bg-center">
      <div className="container">
        <main className="flex flex-1 flex-col justify-between px-4 py-4">
          <div className="mt-16">
            <h1 className="mt-6 mb-2 text-center text-5xl font-bold uppercase leading-tight text-white">
              <div>Erikos gimtadienis!</div>
              <span>🎂</span>
            </h1>
          </div>
          <div className="m-12 flex flex-col justify-center align-bottom">
            <button
              className="btn-primary btn mb-4"
              onClick={(e) => {
                e.preventDefault();
                goToIntro("lt");
              }}
            >
              <span className="mr-4 text-xl">🇱🇹</span>
              Pradėti
            </button>
            <button
              className="btn-primary btn mb-4"
              onClick={(e) => {
                e.preventDefault();
                goToIntro("en");
              }}
            >
              <span className="mr-4 text-xl">🇬🇧</span>
              Begin
            </button>
          </div>
          <input type="checkbox" id="my-modal" className="modal-toggle" />
          <label htmlFor="my-modal" className="modal cursor-pointer">
            <label className="modal-box relative bg-slate-700" htmlFor="my-modal">
              <div className="p-4 text-center font-bold text-white">
                <h3 className="text-lg font-bold">Susisiek su mumis!</h3>
                <p className="py-4 font-light">
                  Domina programėlė? Norėtumei tokios savo šventei? Palikite savo el paštą apačioję ir mes su jumis
                  susisieksim!
                </p>
              </div>
              <form
                className="text-center"
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="hidden">
                  <label>
                    Don’t fill this out if you’re human: <input name="bot-field" />
                  </label>
                </div>
                <input
                  name="email"
                  type="email"
                  required
                  placeholder="pvz: elpastas@pastas.lt"
                  className="input-bordered input w-full max-w-xs"
                />
                <button type="submit" className="btn-primary btn ml-4 mt-4">
                  Susisiekti
                </button>
              </form>
            </label>
          </label>
          <label htmlFor="my-modal">
            <div className="mb-24 text-center">
              <div>
                <div className="font-strong mb-2 text-center text-sm text-white">Patiko programėlė?</div>
                <a className="p-2 text-center text-xs text-white">
                  Norėtum tokios savo gimtadienio ar vestuvių šventėje?
                </a>
              </div>
              <div className="mb-24 p-2 text-center text-xs font-bold text-white underline">Spausk čia!</div>
            </div>
          </label>
        </main>
      </div>
    </div>
  );
};
