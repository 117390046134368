import { Footer } from "@components/Footer";
import { HighscoreTable } from "@components/HighscoreTable";
import { useTranslation } from "react-i18next";

export const Highscores = () => {
  const { t } = useTranslation("highscores");

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <main className="flex-1 overflow-y-scroll">
        <h1 className="mb-8 mt-16 text-center font-tangerine text-5xl font-semibold">{t("title")}</h1>
        <div className="w-full px-4">
          <HighscoreTable />
        </div>
      </main>
      <Footer />
    </div>
  );
};
