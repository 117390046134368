import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { usePersistedState } from "./useLocalStoarge";

// When used redirects user back to home page if they're in a further step but has no team yet
export const useHasNoTeam = () => {
  const [appData] = usePersistedState();
  const navigate = useNavigate();
  console.log("appData->", appData);

  const userHasNoTeam = !appData?.team?.teamKey;
  if (userHasNoTeam) navigate("/");
};

export const useHasTeam = () => {
  const [appData] = usePersistedState();
  const navigate = useNavigate();

  console.log("appData->", appData);

  useEffect(() => {
    const userHasTeam = !!appData?.team?.teamKey;
    if (userHasTeam) {
      navigate("/challenges");
    }
  }, [appData]);
};

//   useEffect(() => {
//     const isBrowser = typeof window !== "undefined";
//     if (isBrowser) {
//       const appDataString = localStorage.getItem(LOCAL_STORAGE_KEY);
//       if (appDataString) {
//         const appData = JSON.parse(appDataString) as AppData;
//         setAppStateData(appData);
//         navigate("/challenges");
//       }
//     }
//   }, []);
// };
