import { Footer } from "@components/Footer";
import { TopNav } from "@components/TopNav";
import { ref as dbRef, update } from "firebase/database";
import { useTranslation } from "react-i18next";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { useConfetti } from "src/helpers/useConfetti";
import { usePersistedState } from "src/helpers/useLocalStoarge";

export const questions = [
  {
    id: 1,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: false },
      { id: 3, correct: true },
      { id: 4, correct: false },
    ],
  },
  {
    id: 2,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: true },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 3,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: false },
      { id: 3, correct: true },
      { id: 4, correct: false },
    ],
  },
  {
    id: 4,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: true },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 5,
    answers: [
      { id: 1, correct: true },
      { id: 2, correct: false },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 6,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: false },
      { id: 3, correct: false },
      { id: 4, correct: true },
    ],
  },
  {
    id: 7,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: false },
      { id: 3, correct: true },
      { id: 4, correct: false },
    ],
  },
  {
    id: 8,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: true },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 9,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: true },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 10,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: true },
      { id: 3, correct: false },
      { id: 4, correct: false },
    ],
  },
  {
    id: 11,
    answers: [
      { id: 1, correct: false },
      { id: 2, correct: false },
      { id: 3, correct: false },
      { id: 4, correct: true },
    ],
  },
];
const allowedPids = questions.map((q) => `${q.id}`);

export const Question: FC = () => {
  const { t } = useTranslation("challenges");
  const { db } = useContext(FirebaseContext);
  const [appData, setAppData] = usePersistedState();
  const teamsDbRef = dbRef(db, "teams");
  const navigate = useNavigate();
  useConfetti("submit-button");
  const { pid } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const teamKey = (appData.team as Team).teamKey;

  const wrongUrl = typeof pid !== "string" || !allowedPids.includes(pid);
  if (wrongUrl) navigate(-1);

  const questionId = Number(pid as string);

  useEffect(() => {
    if (appData.team.challenges?.quiz?.completed) navigate("/challenges");
  }, []);

  const updateAnswer = (answerId: number) => {
    const newAppData = { ...appData };
    newAppData.team.challenges["quiz"].answers[questionId] = answerId;
    setAppData(newAppData);

    const updates = {
      [`${teamKey}/challenges/quiz/answers/${pid}`]: answerId,
    };

    update(teamsDbRef, updates);
  };

  const isAnswered = (answerId: number) => appData.team.challenges["quiz"]?.answers[questionId] === answerId;
  const answer = appData.team?.challenges["quiz"]?.answers[questionId];
  const hasAnswer = answer === undefined || answer === null ? false : true;

  const previousQuestionId = questionId > 1 ? questionId - 1 : null;
  const nextQuestionId = questionId < questions.length ? questionId + 1 : null;
  const lastQuestion = questionId === questions.length;

  const question = questions.find((q) => q.id === questionId);

  const submit = () => {
    const answers = appData.team.challenges?.quiz?.answers;
    const correctQuestions = questions.reduce((acc, q) => {
      const answer = answers ? answers[q.id] : undefined;
      const correctAnswer = q.answers.find((a) => a.correct);
      if (answer === correctAnswer?.id) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const allQuestions = questions.length;
    const ratio = correctQuestions / allQuestions;
    const newPoints = ratio * 500;

    setAppData({
      team: {
        ...appData.team,
        points: appData.team.points + newPoints,
        challenges: {
          ...appData.team.challenges,
          quiz: {
            ...appData.team.challenges["quiz"],
            completed: true,
          },
        },
      },
    });

    const updates = {
      [`${teamKey}/challenges/quiz/completed`]: true,
      [`${teamKey}/points`]: (appData.team as Team).points + newPoints,
    };

    setIsSubmitting(true);
    update(teamsDbRef, updates).then(() => {
      setIsSubmitting(false);
      navigate("/challenges");
    });
  };

  return (
    <div className="h-screen">
      <main>
        <TopNav backTitle={t("back")} />
        <div className="mt-4 mb-8 flex flex-col items-center">
          <h2 className="my-10 px-6 text-center text-2xl font-semibold">{t(`quizChallenge${questionId}Question`)}</h2>
          <div className="mx-6 w-32">
            {question.answers.map(({ id }) => (
              <div key={id} className="mb-4 flex justify-center">
                <button
                  className={`btn ${isAnswered(id) ? "btn-primary btn-active" : "btn"}`}
                  onClick={() => updateAnswer(id)}
                >
                  {t(`quizChallenge${questionId}Answer${id}`)}
                </button>
              </div>
            ))}
            <div className="my-12 mb-12 flex justify-center">
              {previousQuestionId && (
                <Link to={`/challenges/quiz/${previousQuestionId}`}>
                  <button className="btn-outline btn mx-4">{t("back")}</button>
                </Link>
              )}
              {nextQuestionId && (
                <button
                  disabled={!hasAnswer}
                  onClick={() => {
                    if (hasAnswer) navigate(`/challenges/quiz/${nextQuestionId}`);
                  }}
                  className={`btn-outline btn mx-4 ${hasAnswer ? "" : "pointer-events-none touch-none"}`}
                >
                  {t("next")}
                </button>
              )}
              <button
                disabled={!hasAnswer || isSubmitting}
                onClick={submit}
                className={`btn-primary btn ${lastQuestion ? "" : "hidden"}`}
                id="submit-button"
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
