import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { Footer } from "@components/Footer";
import { FullPicker } from "@components/ImagePicker/FullPicker";
import { TopNav } from "@components/TopNav";
import { ref as dbRef, update } from "firebase/database";
import { ref as storageRef, updateMetadata, uploadBytes } from "firebase/storage";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { useConfetti } from "src/helpers/useConfetti";
import { usePersistedState } from "src/helpers/useLocalStoarge";

export const Shroom = () => {
  const { t } = useTranslation("challenges");
  const [appData, setAppData] = usePersistedState();
  const { db, storage } = useContext(FirebaseContext);
  const teamsDbRef = dbRef(db, "teams");
  const [image, setImage] = useState(undefined);
  const navigate = useNavigate();
  useConfetti("submit-button");

  const teamKey = (appData.team as Team).teamKey;

  const submit = async () => {
    const imagePath = `teams/${teamKey}/shroom`;
    const imageRef = storageRef(storage, imagePath);
    await uploadBytes(imageRef, image);

    try {
      const newMetadata = { cacheControl: "public,max-age=1800" }; // Cache for 30min
      await updateMetadata(imageRef, newMetadata);
    } catch (error) {
      console.log(error);
    }

    const newTeam = { ...appData.team };
    newTeam.challenges["shroom"] = {
      ...(newTeam.challenges["shroom"] || {}),
      points: newTeam.points + 100,
      completed: true,
    };

    setAppData({ team: newTeam });

    const updates = {
      [`${teamKey}/challenges/shroom/completed`]: true,
      [`${teamKey}/points`]: (appData.team as Team).points + 100,
    };
    await update(teamsDbRef, updates);

    navigate("/challenges");
  };

  const completed = appData.team.challenges?.shroom?.completed;
  return (
    <div className="h-screen">
      <main>
        <TopNav backTitle={t("back")} />
        <h1 className="my-16 mx-4 text-center font-tangerine text-5xl font-semibold">{t("shroomChallengeTitle")}</h1>
        <div className="mt-4 mb-8 flex flex-col items-center">
          <div className="mx-6">
            {completed ? (
              <>
                <h2 className="text-center text-xl font-bold">{t("challengeDone")}</h2>
                <div className="p-12">
                  <FirebaseCachedImage uri={`teams/${appData.team.teamKey}/shroom`} alt="" />
                </div>
              </>
            ) : (
              <>
                <h2 className="text-center text-2xl">{t("shroomChallengeAbout")}</h2>
                <p className="text-md my-6 text-center">{t("shroomChallengeCopy1")}</p>
                <p className="text-md my-6 text-center">{t("shroomChallengeCopy2")}</p>
                <p className="mt-6 text-center text-lg italic">{t("shroomChallengeCopy3")}</p>
                <p className="mb-6 text-center text-lg italic">{t("shroomChallengeCopy4")}</p>
                <FullPicker
                  uploadLabel={t("upload")}
                  reuploadLabel={t("reupload")}
                  onNewImage={setImage}
                  captureEnvironment={true}
                />
                <div className="mb-12 flex justify-center">
                  <button disabled={!image} onClick={submit} className="btn-primary btn" id="submit-button">
                    {t("submit")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
