import { FirebaseApp, getApps, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Database, getDatabase } from "firebase/database";
import { FirebaseStorage, getStorage } from "firebase/storage";
import React from "react";

export const getConfig = () => {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
};

export interface Firebase {
  instance: FirebaseApp;
  storage: FirebaseStorage;
  auth: Auth;
  db: Database;
}

const initFirebase = () => {
  const config = getConfig();

  const apps = getApps();
  const instance = apps.length < 1 ? initializeApp(config) : apps[0];
  const storage = getStorage(instance);
  const auth = getAuth(instance);
  const db = getDatabase(instance);

  return {
    instance,
    storage,
    auth,
    db,
  };
};

export const FirebaseContext = React.createContext<Firebase>({} as Firebase);

export default initFirebase;
