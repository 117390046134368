import { Link, useNavigate } from "react-router-dom";

import { FC } from "react";

interface Props {
  children: React.ReactNode;
  href: string;
  active?: boolean;
}

export const FooterButton: FC<Props> = ({ children, href, active }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`border-t border-slate-300 border-opacity-50 bg-base-300 shadow-xl dark:border-base-300 ${
        active ? "bg-opacity-20 text-slate-900 dark:text-slate-100" : "text-neutral"
      }`}
      onClick={() => navigate(href)}
    >
      <button>{children}</button>
    </div>
  );
};
