import { useState } from "react";

const LOCAL_STORAGE_KEY = "birthdayAppData";

const safeDefault: object = {
  team: {
    challenges: {
      quiz: {
        answers: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
          7: null,
          8: null,
          9: null,
          10: null,
          11: null,
          12: null,
        },
      },
    },
  },
};

export interface AppData {
  team: Team;
}

export function usePersistedState() {
  const isBrowser = typeof window !== "undefined";

  const [storedValue, setStoredValue] = useState(() => {
    if (!isBrowser) {
      return safeDefault;
    }
    try {
      const item = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      return item ? JSON.parse(item) : safeDefault;
    } catch (error) {
      console.log(error);
      return safeDefault;
    }
  });
  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (isBrowser) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
