import { Footer } from "@components/Footer";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePersistedState } from "src/helpers/useLocalStoarge";
import { questions } from "../question";

export const Quiz = () => {
  const { t } = useTranslation("challenges");
  const [appData] = usePersistedState();
  const [message, setMessage] = useState("");

  const answers = appData.team.challenges?.quiz?.answers ?? {};

  useEffect(() => {
    const correctQuestions = questions.reduce((acc, q) => {
      const correctAnswer = q.answers.find((a) => a.correct);
      if (answers[q.id] === correctAnswer?.id) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const allQuestions = questions.length;
    setMessage(`${t("answered")} ${correctQuestions} / ${allQuestions} ${t("correctly")}!`);
  }, []);

  if (appData.team.challenges?.quiz?.completed) {
    return (
      <div className="h-screen">
        <main>
          <TopNav backTitle={t("back")} />
          <h1 className="my-24 px-10 text-center text-3xl font-semibold">{t("quizChallengeCompleted")}</h1>
          <p className="my-24 px-10 text-center text-lg font-bold">{message}</p>
          {questions.map((q) => {
            const isCorrect = q.answers.find((a) => a.correct)?.id === answers[q.id];
            const answerExists = answers[q.id] !== undefined;
            if (!answerExists) return <></>;
            return (
              <div key={q.id} className="text-md my-24 px-10 text-center">
                <p className=" font-semibold">{t(`quizChallenge${q.id}Question`)}</p>
                <span className="text-sm">{t(`quizChallenge${q.id}Answer${answers[q.id]}`)}</span>
                <span className="ml-4 ">{isCorrect ? `✅` : `❌`}</span>
              </div>
            );
          })}
          <Footer />
        </main>
      </div>
    );
  }

  return (
    <div className="h-screen">
      <main>
        <TopNav backTitle={t("back")} />
        <h1 className="my-16 mx-4 text-center font-tangerine text-5xl font-semibold">{t("quizChallengeAbout")}</h1>
        <div className="mt-4 mb-8 flex flex-col items-center">
          <div className="mx-6">
            <h2 className="text-center text-xl">{t("quizChallengeAbout")}</h2>
            <div className="my-12 flex justify-center">
              <Link to="1">
                <button className="btn-primary btn my-6">{t("start")}</button>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
