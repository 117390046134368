import { ImagePicker } from "@components/ImagePicker";
import { Input } from "@components/Input";
import { push, ref as dbRef } from "firebase/database";
import { ref as storageRef, updateMetadata, uploadBytes } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FirebaseContext } from "src/helpers/firebase";
import { usePersistedState } from "src/helpers/useLocalStoarge";

const defaultChallenges = {
  quiz: {
    answers: {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
    },
  },
};

export const NewTeam = () => {
  const { t } = useTranslation("newteam");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { db, storage } = useContext(FirebaseContext);
  const teamsDbRef = dbRef(db, "teams");
  const [loading, setLoading] = useState(false);

  const [appData, setAppData] = usePersistedState();

  const [image, setImage] = useState<undefined | File>(undefined);

  useEffect(() => {
    const teamExists = !!appData?.team?.teamKey;
    if (teamExists) navigate("/challenges");
  }, [appData]);

  const onSubmit = async (data) => {
    setLoading(true);
    const teamRef = await push(teamsDbRef, {
      teamName: data.teamName,
      points: 0,
      challenges: defaultChallenges,
    });
    const imagePath = `teams/${teamRef.key}/avatar`;
    const imageRef = storageRef(storage, imagePath);
    await uploadBytes(imageRef, image);

    // Cache for 30mins
    const newMetadata = {
      cacheControl: "public,max-age=1800",
    };
    try {
      await updateMetadata(imageRef, newMetadata);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setAppData({ team: { teamKey: teamRef.key, points: 0, teamName: data.teamName, challenges: defaultChallenges } });
  };

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <main className="flex-1 overflow-y-scroll p-4">
        <h1 className="mt-16 mb-8 text-center font-tangerine text-5xl font-semibold">{t("title")}</h1>
        <h1 className="mt-4 mb-8 text-center text-lg">{t("about")}</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100">
          <div className="flex flex-col items-center">
            <Input
              label={t("labelTeamName")}
              name="teamName"
              required
              placeholder={t("placeholderTeamName")}
              requiredMessage={t("fieldRequired")}
              errors={errors}
              register={register}
            />
            <br />
            {/* <Input
              label={t("labelYou")}
              name="username"
              placeholder={t("placeholderYou")}
              errors={errors}
              required
              register={register}
            />
            <Input
              hidden={showMembers < 1}
              label={t("labelOtherMember")}
              name="member1"
              placeholder={t("placeholderMember1")}
              errors={errors}
              register={register}
            />
            <Input
              hidden={showMembers < 2}
              label={t("labelOtherMember")}
              name="member2"
              placeholder={t("placeholderMember2")}
              errors={errors}
              register={register}
            />
            <Input
              hidden={showMembers < 3}
              label={t("labelOtherMember")}
              name="member3"
              placeholder={t("placeholderMember3")}
              errors={errors}
              register={register}
            />
            {showMembers < 3 && (
              <button
                className="btn btn-outline btn-circle my-6"
                type="button"
                onClick={() => {
                  setShowMembers(showMembers + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>
              </button>
            )} */}
          </div>
          <ImagePicker label={t("teamPhoto")} uploadLabel={t("uploadPhoto")} onNewImage={setImage} />
          <div className="mx-12 mb-12 mt-6 flex justify-center">
            <button disabled={loading || !image} className={`${loading ? "loading" : ""} btn-primary btn mb-4`}>
              {t("create")}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};
