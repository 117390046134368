import { ref } from "firebase/database";
import { useTranslation } from "react-i18next";
import { FC, useContext, useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { FirebaseContext } from "src/helpers/firebase";
import { useHasNoTeam } from "src/helpers/useHasNoTeam";
import { usePersistedState } from "src/helpers/useLocalStoarge";
import { TeamRow } from "./TeamRow";

export const HighscoreTable: FC = () => {
  const { t } = useTranslation("highscores");

  const [appData] = usePersistedState();

  const { db } = useContext(FirebaseContext);
  const teamsDbRef = ref(db, "teams");
  const [teams, _loading, _error] = useObjectVal<Team>(teamsDbRef);

  useHasNoTeam();

  const sortTeamsByPoints = (teamA: Team, teamB: Team) => (teamA.points < teamB.points ? 1 : -1);

  const currentTeamKey = appData?.team?.teamKey;

  const [teamsArray, setTeamsArray] = useState<Team[]>([]);
  useEffect(() => {
    if (teams) {
      const teamsArray = Object.entries(teams).map(([teamKey, team]) => ({
        teamKey,
        teamName: team.teamName,
        points: team.points,
        challenges: team.challenges,
      }));
      setTeamsArray(teamsArray);
    }
  }, [teams]);

  return (
    <table className="highscore-table mb-32 table w-full">
      <thead>
        <tr>
          <th>#</th>
          <th>{t("team")}</th>
          <th></th>
          <th>{t("score")}</th>
        </tr>
      </thead>
      <tbody>
        {teamsArray.sort(sortTeamsByPoints).map((team, index) => (
          <TeamRow
            active={team.teamKey === currentTeamKey}
            key={team.teamKey}
            index={index + 1}
            teamKey={team.teamKey}
            teamName={team.teamName}
            points={Math.round(team.points)}
          />
        ))}
      </tbody>
    </table>
  );
};
