import * as imageConversion from "image-conversion";
import { FC, useRef, useState } from "react";

interface Props {
  label?: string;
  uploadLabel: string;
  reuploadLabel: string;
  onNewImage?: (file: File) => void;
  captureEnvironment?: boolean;
}

export const FullPicker: FC<Props> = ({ label, uploadLabel, reuploadLabel, onNewImage, captureEnvironment }) => {
  const inputEl = useRef(null);
  const imageEl = useRef(null);

  const [showPreview, setShowPreview] = useState(false);

  const handleInput = (e) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length > 0) {
      const file = ((window as any).file = files[0]);
      imageConversion
        .compress(file, {
          quality: 0.8,
          scale: 0.5,
        })
        .then((blob) => {
          const newFile = blob as File;
          setShowPreview(true);
          try {
            const url = window.URL.createObjectURL(newFile);
            imageEl.current.src = url;
          } catch (event) {
            try {
              const fileReader = new FileReader();
              fileReader.onload = function (fileReaderEvent) {
                (inputEl.current as any).src = fileReaderEvent.target.result;
              };
              fileReader.readAsDataURL(newFile);
            } catch (error) {
              console.log("Neither createObjectURL or FileReader are supported", error);
            }
          }
          if (onNewImage) onNewImage(newFile);
        });
    }
  };
  return (
    <div className="flex flex-col justify-center">
      <div className="my-8 text-center">
        {label ? <h2 className="mb-2 mb-4">{label}</h2> : null}

        <div className="flex items-center justify-center">
          <label
            htmlFor="dropzone-file"
            className={`flex ${
              showPreview
                ? ""
                : "h-48 w-48 rounded-2xl border border-dashed border-neutral bg-base-200 dark:border-slate-400"
            } cursor-pointer flex-col items-center justify-center `}
          >
            <div className={`mx-10 flex flex-col items-center justify-center pt-5 pb-6 ${showPreview ? "hidden" : ""}`}>
              <svg
                aria-hidden="true"
                className="mb-3 h-10 w-10 text-neutral dark:text-slate-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-neutral dark:text-slate-500">{uploadLabel}</p>
            </div>
            <div className={`flex flex-col items-center justify-center pt-5 pb-6 ${showPreview ? "" : "hidden"}`}>
              <div className="mb-4 flex justify-center">
                <img ref={imageEl} className="max-h-96 w-screen object-scale-down" />
              </div>
              <p className="mb-2 text-xs text-neutral underline dark:text-slate-400">{reuploadLabel}</p>
            </div>
            <input
              onChange={handleInput}
              id="dropzone-file"
              ref={inputEl}
              type="file"
              accept="image/*"
              capture={captureEnvironment ? "environment" : "user"}
              className="hidden"
            />
          </label>
        </div>
      </div>
    </div>
  );
};
