import { Footer } from "@components/Footer";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useHasNoTeam } from "src/helpers/useHasNoTeam";
import { usePersistedState } from "src/helpers/useLocalStoarge";

export const Challenges: FC = () => {
  const { t } = useTranslation("challenges");

  const [appData] = usePersistedState();
  useHasNoTeam();
  const teamChallenges = appData?.team?.challenges || {};

  const navigate = useNavigate();

  const challenges = [
    {
      id: 1,
      title: t("selfieChallengeTitle"),
      about: t("selfieChallengeAbout"),
      link: "/challenges/selfie",
      points: 200,
      complete: teamChallenges.selfie?.completed ?? false,
    },
    {
      id: 2,
      title: t("quizChallengeTitle"),
      about: t("quizChallengeAbout"),
      link: "/challenges/quiz",
      points: 500,
      complete: teamChallenges.quiz?.completed ?? false,
    },
    {
      id: 3,
      title: t("dockerChallengeTitle"),
      about: t("dockerChallengeAbout"),
      link: "/challenges/docker",
      points: 100,
      complete: teamChallenges.docker?.completed ?? false,
    },
    {
      id: 4,
      title: t("guestbookChallengeTitle"),
      about: t("guestbookChallengeAbout"),
      link: "/challenges/guestbook",
      points: 300,
      complete: teamChallenges.guestbook?.completed ?? false,
    },
    {
      id: 6,
      title: t("cakeChallengeTitle"),
      about: t("cakeChallengeAbout"),
      link: "/challenges/cake",
      points: 100,
      complete: teamChallenges.cake?.completed ?? false,
    },
    {
      id: 7,
      title: t("kissChallengeTitle"),
      about: t("kissChallengeAbout"),
      link: "/challenges/kiss",
      points: 100,
      complete: teamChallenges.kiss?.completed ?? false,
    },
    {
      id: 8,
      title: t("danceChallengeTitle"),
      about: t("danceChallengeAbout"),
      link: "/challenges/dance",
      points: 100,
      complete: teamChallenges.dance?.completed ?? false,
    },
    {
      id: 9,
      title: t("laughChallengeTitle"),
      about: t("laughChallengeAbout"),
      link: "/challenges/laugh",
      points: 100,
      complete: teamChallenges.laugh?.completed ?? false,
    },
    {
      id: 11,
      title: t("boringChallengeTitle"),
      about: t("boringChallengeAbout"),
      link: "/challenges/boring",
      points: 100,
      complete: teamChallenges.boring?.completed ?? false,
    },
    {
      id: 12,
      title: t("generationsChallengeTitle"),
      about: t("generationsChallengeAbout"),
      link: "/challenges/generations",
      points: 100,
      complete: teamChallenges.generations?.completed ?? false,
    },
  ];

  return (
    <div className="h-screen">
      <main>
        <TopNav />
        <h1 className="mt-16 mb-8 text-center font-tangerine text-5xl font-bold">{t("title")}</h1>
        <div className="mt-4 mb-8 flex flex-col items-center">
          <div className="mx-6">
            {challenges.map(({ id, title, about, points, link, complete }) => (
              <div key={id} className="mb-6 " role="button" onClick={() => navigate(link)}>
                <div
                  className={`card w-full ${
                    complete ? "bg-emerald-300" : "border-2 border-base-300 bg-base-200"
                  } shadow-xl`}
                >
                  <div className="card-body">
                    {complete && (
                      <div className="flex justify-center">
                        <div className="z-100 text-cener absolute text-gray-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-16 w-16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    )}

                    <div className={`${complete ? "blur-xs" : ""}`}>
                      <div className="flex justify-between">
                        <h2 className="card-title">{title}</h2>
                        <div className="text-sm font-semibold">{points}</div>
                      </div>
                      <p>{about}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
