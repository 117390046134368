import { getDownloadURL, ref } from "firebase/storage";
import { FC, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";

interface Props {
  uri: string;
  defaultUri?: string;
  alt?: string;
}

export const FirebaseCachedImage: FC<Props> = ({ uri, defaultUri, alt }) => {
  const cache = useContext(ImageCacheContext);
  const { storage } = useContext(FirebaseContext);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleDefault = async () => {
    const defaultUrl = cache.current.get(defaultUri);
    if (defaultUrl) {
      setImageUrl(defaultUrl);
    } else {
      const downloadRef = ref(storage, defaultUri);
      const url = await getDownloadURL(downloadRef);
      cache.current.set(uri, url);
      setImageUrl(url);
    }
  };

  const downloadImage = async (uri: string) => {
    try {
      const downloadRef = ref(storage, uri);
      const url = await getDownloadURL(downloadRef);
      cache.current.set(uri, url);
      setImageUrl(url);
    } catch (error) {
      const notFound = error.message.includes("does not exist");
      if (notFound && defaultUri) await handleDefault();
    }
  };

  useEffect(() => {
    if (cache.current.has(uri)) {
      const imageUrl = cache.current.get(uri);
      setImageUrl(imageUrl);
    } else {
      downloadImage(uri);
    }
  }, []);

  return <img src={imageUrl} alt={alt} />;
};
