import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { FC } from "react";

interface Props {
  index: number;
  teamKey: string;
  teamName: string;
  points: number;
  active?: boolean;
}

export const TeamRow: FC<Props> = ({ index, teamName, points, teamKey, active }) => {
  return (
    <tr key={teamKey} className={`${active ? "myteam" : ""} p-1`}>
      <th className="px-4">{index}</th>
      <td className="px-4">
        <div className="px-4 font-bold">{teamName}</div>
      </td>
      <td className="px-4">
        <div className="avatar h-12 w-12">
          <div className="mask mask-squircle h-12 w-12 p-1">
            <FirebaseCachedImage uri={`teams/${teamKey}/avatar`} defaultUri="anonymous.webp" alt={teamName} />
          </div>
        </div>
      </td>
      <td>{points}</td>
    </tr>
  );
};
