import { ref } from "firebase/database";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { FirebaseContext } from "src/helpers/firebase";
import { usePersistedState } from "src/helpers/useLocalStoarge";

const defaultChallenges = {
  quiz: {
    answers: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false },
  },
};

export const SelectTeam = () => {
  const { t } = useTranslation("selectteam");

  const [teamSelected, setTeamSelected] = useState<Team | undefined>();

  const [appData, setAppData] = usePersistedState();
  const { db } = useContext(FirebaseContext);
  const teamsDbRef = ref(db, "teams");
  const [teams, _loading, _error] = useObjectVal<Team>(teamsDbRef);

  const navigate = useNavigate();

  const handleTeamSelect = () => {
    setAppData({ team: teamSelected });
  };

  useEffect(() => {
    const userHasTeam = appData?.team?.teamKey;
    if (userHasTeam) navigate("/challenges");
  }, [appData]);

  const [teamsArray, setTeamsArray] = useState<Team[]>([]);
  useEffect(() => {
    if (teams) {
      const teamsArray = Object.entries(teams).map(([teamKey, team]) => ({
        teamKey,
        teamName: team.teamName,
        points: team.points,
        challenges: team.challenges || defaultChallenges,
      }));
      setTeamsArray(teamsArray);
    }
  }, [teams]);

  return (
    <div className="flex flex-col">
      <main className="flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center font-tangerine text-5xl font-semibold">{t("title")}</h1>
          <h2 className="text mt-16 mb-8 text-center text-lg">{t("about")}</h2>
        </div>
        <div className="mx-6 flex justify-center">
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">{t("pickExisting")}</span>
            </label>
            <select
              className="select-bordered select bg-gray-200"
              onChange={(e) => {
                const teamName = e.target.value;
                const teamSelected = teamsArray.find((team) => team.teamName === teamName);
                setTeamSelected(teamSelected);
              }}
            >
              <option disabled selected>
                {t("selectTeam")}
              </option>
              {teamsArray.map(({ teamKey, teamName }) => (
                <option key={teamKey}>{teamName}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-12 flex justify-center">
          <button className="btn-primary btn mb-4 px-4" disabled={!teamSelected} onClick={handleTeamSelect}>
            {t("play")}
          </button>
        </div>
      </main>
    </div>
  );
};
