import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Teams = () => {
  const { t } = useTranslation("teams");

  return (
    <div className="flex flex-col">
      <main className="flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center font-tangerine text-5xl font-semibold">{t("title")}</h1>
          <h2 className="mt-16 mb-8 text-center text-xl font-semibold">{t("about")}</h2>
        </div>
        <div className="mt-16 mb-8 text-center text-lg">{t("dontHaveTeamYet")}</div>
        <div className="mt-16 flex justify-center">
          <Link to="/newteam">
            <button className="btn-primary btn mb-4 px-4">{t("createTeam")}</button>
          </Link>
        </div>
        <div className="mt-4 flex justify-center text-sm text-neutral dark:text-slate-400">
          <Link to="/selectteam">
            <span className="underline">{t("alreadyHaveTeam")}</span>
          </Link>
        </div>
      </main>
    </div>
  );
};
