import "./styles/globals.css";
import "./styles/imagePreview.css";
import { useRef } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import initFirebase, { FirebaseContext } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";
import { Landing } from "./pages/landing";
import { Highscores } from "./pages/highscores";
import { Intro } from "./pages/intro";
import { NewTeam } from "./pages/newteam";
import { Teams } from "./pages/teams";
import { SelectTeam } from "./pages/selectteam";
import { Boring } from "./pages/challenges/boring";
import { Cake } from "./pages/challenges/cake";
import { Cry } from "./pages/challenges/cry";
import { Dance } from "./pages/challenges/dance";
import { Docker } from "./pages/challenges/docker";
import { Generations } from "./pages/challenges/generations";
import { GuestBook } from "./pages/challenges/guestbook";
import { Kiss } from "./pages/challenges/kiss";
import { Laugh } from "./pages/challenges/laugh";
import { Quiz } from "./pages/challenges/quiz";
import { Selfie } from "./pages/challenges/selfie";
import { Shroom } from "./pages/challenges/shroom";

import enChallenges from "./locales/en/challenges.json";
import enHighscores from "./locales/en/highscores.json";
import enHome from "./locales/en/home.json";
import enIntro from "./locales/en/intro.json";
import enNewTeam from "./locales/en/newteam.json";
import enSelectTeam from "./locales/en/selectteam.json";
import enTeams from "./locales/en/teams.json";

import ltChallenges from "./locales/lt/challenges.json";
import ltHighscores from "./locales/lt/highscores.json";
import ltHome from "./locales/lt/home.json";
import ltIntro from "./locales/lt/intro.json";
import ltNewTeam from "./locales/lt/newteam.json";
import ltSelectTeam from "./locales/lt/selectteam.json";
import ltTeams from "./locales/lt/teams.json";
import { Challenges } from "./pages/challenges";
import { Question } from "./pages/challenges/question";

i18n
  .use(initReactI18next) // Bind react-i18next to i18next
  .init({
    resources: {
      en: {
        challenges: enChallenges,
        highscores: enHighscores,
        home: enHome,
        intro: enIntro,
        newteam: enNewTeam,
        selectteam: enSelectTeam,
        teams: enTeams,
      },
      lt: {
        challenges: ltChallenges,
        highscores: ltHighscores,
        home: ltHome,
        intro: ltIntro,
        newteam: ltNewTeam,
        selectteam: ltSelectTeam,
        teams: ltTeams,
      },
    },
    lng: "en", // Set the default language
    fallbackLng: "en", // Use English if a translation for the current language is missing
    interpolation: { escapeValue: false }, // Disable HTML escaping
  });

const Application = () => {
  const cache = useRef(new Map<string, string>());

  return (
    <ImageCacheContext.Provider value={cache}>
      <FirebaseContext.Provider value={initFirebase()}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Landing />} />
            <Route path="/" element={<Landing />} />
            <Route path="/highscores" element={<Highscores />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/newteam" element={<NewTeam />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/selectteam" element={<SelectTeam />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/challenges/boring" element={<Boring />} />
            <Route path="/challenges/cake" element={<Cake />} />
            <Route path="/challenges/cry" element={<Cry />} />
            <Route path="/challenges/dance" element={<Dance />} />
            <Route path="/challenges/docker" element={<Docker />} />
            <Route path="/challenges/generations" element={<Generations />} />
            <Route path="/challenges/guestbook" element={<GuestBook />} />
            <Route path="/challenges/kiss" element={<Kiss />} />
            <Route path="/challenges/laugh" element={<Laugh />} />
            <Route path="/challenges/quiz" element={<Quiz />} />
            <Route path="/challenges/quiz/:pid" element={<Question />} />
            <Route path="/challenges/selfie" element={<Selfie />} />
            <Route path="/challenges/shroom" element={<Shroom />} />
          </Routes>
        </BrowserRouter>
      </FirebaseContext.Provider>
    </ImageCacheContext.Provider>
  );
};

export default Application;
