import { FC } from "react";

interface Props {
  label: string;
  name: string;
  placeholder: string;
  required?: boolean;
  requiredMessage?: string;
  errors: any;
  register: any;
  hidden?: boolean;
}

export const Input: FC<Props> = ({ label, name, required, placeholder, hidden, requiredMessage, errors, register }) => {
  return (
    <div className={`form-control w-full max-w-xs ${hidden ? "hidden" : ""}`}>
      <label className="label">
        <span className="label-text">
          {label} {required ? <span className="text-warning">*</span> : <></>}
        </span>
      </label>
      <input
        type="text"
        placeholder={placeholder}
        className="input input-bordered w-full max-w-xs bg-slate-100"
        {...register(name, { required })}
      />
      {errors[name] && <span className="pl-1 pt-1 text-xs text-warning">{requiredMessage}</span>}
    </div>
  );
};
