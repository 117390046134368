import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useHasNoTeam } from "src/helpers/useHasNoTeam";
import { usePersistedState } from "src/helpers/useLocalStoarge";
import { useTranslation } from "react-i18next";

interface Props {
  backTitle?: string;
  logoutTitle?: string;
}

export const TopNav: FC<Props> = ({ backTitle, logoutTitle }) => {
  const [appData, setAppData] = usePersistedState();
  const navigate = useNavigate();

  const { t } = useTranslation("challenges");

  useHasNoTeam();
  const goBack = () => navigate(-1);

  const logout = () => {
    setAppData({} as any);
    navigate("/");
  };

  const teamName = appData?.team?.teamName || "";
  const imageUri = appData?.team ? `teams/${appData.team.teamKey}/avatar` : "anonymous.webp";
  return (
    <div className="navbar absolute sticky top-0 z-50  bg-base-200 px-2 shadow-lg dark:shadow-slate-800">
      <div className="flex-1">
        {backTitle ? (
          <button onClick={goBack} className="link ml-4">
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-1 h-6 w-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>

              {backTitle || t("back")}
            </div>
          </button>
        ) : (
          <a className="ml-4 text-lg uppercase">{teamName}</a>
        )}
      </div>
      <div className="mr-4 flex-none ">
        <div className="dropdown-end dropdown">
          <label tabIndex={0} className="btn-ghost btn-circle avatar btn">
            <div className="h-10 w-10 rounded-full">
              <FirebaseCachedImage uri={imageUri} defaultUri="anonymous.webp" alt="" />
            </div>
          </label>
          <ul tabIndex={0} className="dropdown-content menu rounded-box menu-compact mt-3 w-32 bg-base-300 p-2 shadow">
            <li>
              <a onClick={logout} className="flex flex-row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                  />
                </svg>
                <span>{logoutTitle || t("logout")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
