import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHasTeam } from "src/helpers/useHasNoTeam";

export const Intro = () => {
  const { t } = useTranslation("intro");
  useHasTeam();

  return (
    <div className="flex flex-col">
      <main className="flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center font-tangerine text-5xl font-semibold">{t("title")}</h1>
          <h1 className="mt-16 mb-8 text-center text-2xl font-semibold">{t("welcome")}</h1>
          <div className="w-full px-4 pt-8 text-center">{t("about")}</div>
        </div>
        <div className="mt-20 flex justify-center">
          <Link to="/teams">
            <button className="btn-primary btn mb-4 px-24">{t("start")}</button>
          </Link>
        </div>
      </main>
    </div>
  );
};
